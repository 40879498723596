.footer {
  padding: 3em 6em; }
  .footer .wrapper {
    font-size: 0.8em;
    color: #50554b;
    letter-spacing: 0;
    text-align: center;
    line-height: 25px;
    border-top: 1px solid #e5e5e5;
    padding: 2em; }
    .footer .wrapper a {
      color: #50554b; }
